import PropTypes from "prop-types"
import React from "react"
import "./TextField.css"

const TextField = (props) => {
  let fieldSize
  if (props.size === "small") {
    if (props.placeholder?.length > 12) {
        fieldSize = props.placeholder.length
      } else {
        fieldSize = 6
      }
  }

  return (
    <div
      className={`text-field ${props.size} ${props.type} ${props.className}`}
    >
      <input
        type={props.type}
        className={`field ${props.className}`}
        placeholder={props.placeholder}
        onChange={props.onChange}
        name={props.name}
        id={props.id}
        defaultValue={props.value}
        required={props.required}
        size={fieldSize}
      />
      <div className={`helper-text ${props.status}`}>
        <small>{props.helperText}</small>
      </div>
    </div>
  )
}

TextField.propTypes = {
  type: PropTypes.oneOf(["text", "password", "number", "email"]),
  size: PropTypes.oneOf(["large", "compact", "small"]),
  placeholder: PropTypes.string,
  icon: PropTypes.bool,
  validation: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
}

TextField.defaultProps = {
  type: "text",
  size: "large",
}

export default TextField
