import React from "react"
import { Button } from "../components/Button"
import "./OrgName.css"



export const QuickStart = () => {

    return (
      <div className="container dark">
      <div className="section">
      <div className="message">
      <h1>Quickstart Guide</h1>
        <h6>If you&lsquo;re interested in using the APIs you&lsquo;ll want to...</h6>
        <div className="step step-1">
          <a
            className="text-wrapper-2"
            href="https://docs.blindinsight.io/download/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Download the Blind Proxy
          </a>
        </div>
        <h6>WE SUGGEST STARTING WITH ONE OF OUR...</h6>
        <div className="step step-2">

          <a
            className="text-wrapper-2"
            href="https://github.com/blind-insight/demo-datasets"
            rel="noopener noreferrer"
            target="_blank"
          >
            Sample Datasets
          </a>
      </div>

        <h6>AND CONSULTING OUR...</h6>
        <div className="step step-3">        <a
            className="text-wrapper-2"
            href="https://docs.blindinsight.io/getting-started/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Getting started guide
          </a>
        </div>
      <div className="nah full-width">
        <h6>OR, YOU MAY WANT TO JUST GO...</h6>
       <div className="full-width"> <Button className="button-instance" size="large" text="Explore the demo &gt;" /></div>
      </div>
    </div>
          <div className="form-container">
              <div className="logo-vertical" />

        </div>
      </div>
      </div>
  )
}
