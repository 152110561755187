import React, { useMemo } from "react"
import { useRouteLoaderData, useParams, Link } from "react-router-dom"

import { Grid, Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Alert, Tabs, Tab } from "@mui/material"

import colors from "../colors"

import { Datasets, Schemas, PersistedNavigation } from "../lib/store"

const styles = {
    headCell: ({ border }) => ({
        color: "rgba(0, 0, 0, 1)",
        background: "rgba(245, 245, 245, 1)",
        fontSize: "13px",
        fontWeight: 500,
        paddingTop: 1,
        paddingBottom: 1,
        borderBottom: border ? "1px solid rgba(225, 207, 255, 1)" : undefined,
    }),
    cell: {
        fontSize: "13px",
        color: "rgba(51, 51, 51, 1)",
        borderBottom: "1px solid rgba(225, 207, 255, 1)",
    },
    button: {
        height: "49px",
        borderRadius: "100px",
        fontWeight: 600,
        fontSize: "18px",
        color: colors.gray1,
        background: "rgba(40, 22, 98, 1)",
        "&:hover": {
            background: "rgba(40, 22, 98, 1)",
        },
        textTransform: "none",
    },
    link: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    input: {
        "& input": {
            height: "30px",
            color: colors.pink90,
        },
        background:
            "linear-gradient(0deg, #F0F0F0, #F0F0F0), linear-gradient(0deg, #AEAEAE, #AEAEAE)",
        borderRadius: "95px",
        "& fieldset": {
            border: "1px solid rgba(174, 174, 174, 1)",
            borderRadius: "95px",
        },
    },
}

export default function SchemaDetail() {
    const user = useRouteLoaderData("auth")
    const [datasets,] = Datasets.useState()
    const [schemas,] = Schemas.useState()
    const [persistedNavigation, ] = PersistedNavigation.useState()
    const { datasetId, schemaId } = useParams()
    // Find the selected dataset
    const dataset = useMemo(() => datasets[datasetId], [datasets, datasetId])
    // Find the selected schema
    const schema = useMemo(() => schemas[schemaId], [schemas, schemaId])
    // Get the schema properties
    const properties = useMemo(() => schema?.schema?.properties || {}, [schema])
    // Toggle the header's lower border based on property count
    const border = useMemo(() => Object.keys(properties).length || 0, [properties])
    // Render table rows
    const rows = useMemo(() => {
        const entries = Object.entries(properties)
        if (!entries.length) return []
        return entries.map(([name, value]) => {
            let allowed = value.enum || ["Any"]
            allowed = allowed.map((v) => <Grid key={v} item xs={2}>{v}</Grid>)
            allowed = allowed ? <Grid container rowSpacing={0} columnSpacing={1} columns={10}>{allowed}</Grid> : null
            const required = schema?.schema?.required?.includes(name) ? "Yes" : "No"

            return (
                <TableRow
                    key={name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                    <TableCell sx={styles.cell}>{name}</TableCell>
                    <TableCell sx={styles.cell}>{value.type}</TableCell>
                    <TableCell sx={styles.cell}>{required}</TableCell>
                    <TableCell sx={styles.cell}>{allowed}</TableCell>
                </TableRow>
            )
        })
    }, [schema, properties])

    React.useEffect(() => {
        if (persistedNavigation.schemas !== "") {
            PersistedNavigation.updateValue((persistedNav) => {
                persistedNav.schemas = ""
            })
        }
    }, [persistedNavigation])

    // Render nothing if we don't have a user
    if (!user) return null
    // Render a not found if we couldn't find a schema in state
    if (!schema) return (
        <Box p={3}>
            <Alert severity="error">
                Schema not found
            </Alert>
        </Box>
    )

    return (<>
            <Tabs value="schema">
                <Tab label="Schema" value="schema" disabled />
                <Tab
                    label="Records"
                    value="records"
                    component={Link}
                    to={`/datasets/${datasetId}/schemas/${schemaId}/records`}
                />
            </Tabs>
            <div className="view rows">
                <div className="section-header" id="schema-detail-header">
                <h2>
                        <Link style={styles.link} to={`/datasets/${datasetId}`}>{dataset.name} &rarr; </Link>
                        <Link style={styles.link} className="last">{schema.name} &rarr; schema</Link>
                    </h2>
                    Schema description: {schema.description}
                </div>
                <div className="row">
                <TableContainer
                    component={Paper}
                    sx={{
                        borderRadius: "10px",
                        boxShadow: "8px 8px 12px 3px rgba(0, 0, 0, 0.12)",
                    }}
                >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styles.headCell({ border })}>Name</TableCell>
                                <TableCell sx={styles.headCell({ border })}>Type</TableCell>
                                <TableCell sx={styles.headCell({ border })}>Required</TableCell>
                                <TableCell sx={styles.headCell({ border })}>Allowed Values</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows}
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
            </div>
        </>)
}
