import React from "react"

export default function TOU() {

    return (<>
        <div className="rows">
            <b>SOVEREIGN SYSTEMS, INC.</b><br />
            <b>TERMS OF USE</b><br />
            Last Updated: April 16, 2024<br /><br />
            These Terms of Use (these &lsquo;Terms&rsquo;) are a legally binding agreement between the party accepting these Terms as set forth in this paragraph (&lsquo;you&rsquo;) and Sovereign Systems, Inc. (&lsquo;SSI&rsquo;) and applies to your access and use of SSI’s Blind Insight® proprietary software (including application programming interfaces) and related tools and services (collectively, the &lsquo;Services&rsquo;). PLEASE READ THESE TERMS CAREFULLY. WHEN YOU CLICK &lsquo;SUBMIT&rsquo;
            CHECK A BOX, OR OTHERWISE ACCESS OR USE THE SERVICES, YOU ARE AGREEING TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICES.
            BY AGREEING TO THESE TERMS, EXCEPT FOR (A) CERTAIN TYPES OF DISPUTES DESCRIBED IN SECTION 11, (B)  WHERE YOU EXERCISE YOUR RIGHT TO OPT OUT OF ARBITRATION AS DESCRIBED IN SECTION 11, OR (C) TO  THE EXTENT PROHIBITED BY LAW, DISPUTES BETWEEN YOU AND SSI WILL BE RESOLVED SOLELY ON AN  INDIVIDUAL BASIS AND NOT AS A CLASS ARBITRATION, CLASS ACTION, ANY OTHER KIND OF REPRESENTATIVE  PROCEEDING, OR BY JURY TRIAL.
            SSI may make changes to these Terms. If SSI makes changes, SSI may provide you with notice of such changes,  such as by sending an email, providing a notice through the Services, or otherwise. Unless SSI says otherwise in  its notice, the amended Terms will be effective immediately, and your continued use of the Services after SSI provides such notice will confirm your acceptance of the changes. If you do not agree to the amended Terms,  you must immediately stop using the Services. Any changes to these Terms will not apply to any dispute  between you and SSI arising prior to the date on which SSI posted the updated Terms incorporating such  changes or otherwise notified you of such changes.
            <br /><br />
            <b>1. THE SERVICES</b><br />
            <p>1.1 Right to Use. Subject to your compliance with the terms and conditions of these Terms, SSI grants you a  limited, non-exclusive, revocable right to use the Services solely for your internal business purposes on a  device that you own or control. You may not resell, transfer, assign, or sublicense your rights under  these Terms to any third party or use the Services to provide services for the benefit of any third party.
            </p><br /><p>1.2 Third-Party Providers. You acknowledge that SSI may use the services of third-party contractors,  including third-party data centers, cloud providers, and software implementation consultants, in  providing the Services (collectively, &quot;Service Providers&quot;) and that the Services (including Your Encrypted  Data, as defined below) may be hosted and processed on a network owned and maintained by a Service  Provider. The performance of Service Providers is outside SSI&apos;s control. SSI WILL NOT BE LIABLE FOR,  AND SSI EXPRESSLY DISCLAIMS, ANY LIABILITY FOR LOSSES, COSTS, OR EXPENSES CAUSED BY ANY  SERVICE PROVIDERS.
            </p><br /><p>1.3 Modifications. Notwithstanding anything to the contrary in these Terms, (a) SSI may conduct  maintenance on the Services from time to time without prior notice to you and (b) SSI may modify  features of the Services from time to time at SSI&apos;s sole discretion.
            </p>
            <br /><br />
            <b>2. ELIGIBILITY AND ACCOUNTS</b><br />
            <p>2.1 Authorization. If you use the Services on behalf of another person or entity, (a) all references to &quot;you&quot; throughout these Terms (other than in this Section 2.1(a), (b) and (c)) will include that person or entity,  (b) you represent that you are authorized to accept these Terms on that person’s or entity&apos;s behalf, and  (c) in the event you or that person or entity violates these Terms, that person or entity also agrees to be  responsible to us.
            </p><br /><p>2.2 Jurisdiction. You may only use the Services in jurisdictions authorized by SSI. You represent, warrant and  covenant that you are not (a) located in, or a resident or a national of, any country subject to a U.S.  government embargo or other restriction, or that has been designated by the U.S. government as a  &quot;terrorist supporting&quot; country or (b) on any of the U.S. government lists of restricted end users.
            </p><br /><p>2.3 Use and Sharing. The Services are provided to you only for your internal business use and not for the  benefit or use of any third party. SSI may enable you to designate authorized individuals (either &quot;Data  Owners&quot; with read, write, encrypt, and decrypt capabilities or &quot;Data Requesters&quot; with access to  encrypted search and encrypted results only) (&quot;Authorized Users&quot;) to use the Services, and if so, only  Authorized Users may use the Services.
            </p><br /><p>2.4 Access Credentials. You must create an account to use our Services. You are responsible for access to  your account and use of the Services by any Authorized User, as well as for your account access and use  of the Services by any third party through your access credentials, whether authorized or not. You are  responsible for implementing security measures to safeguard your access credentials and to prevent use  and disclosure by unauthorized third parties. You will promptly notify SSI in writing of any unauthorized  use of the Services or access credentials that comes to your attention. Neither SSI nor any of its Service  Providers has any obligation to inquire about the authority of anyone using your personally identifiable  information that can be used to identify your account. SSI WILL NOT BE LIABLE FOR, AND SSI EXPRESSLY  DISCLAIMS, ANY LIABILITY FOR LOSSES, COSTS, OR EXPENSES CAUSED BY ANY UNAUTHORIZED USE OF  THE SERVICES THROUGH YOUR ACCOUNT.
            </p><br /><br />
            <b>3. YOUR OBLIGATIONS</b><br />
            <p>3.1 Restrictions. You agree that the Services contain trade secrets and other valuable proprietary  information belonging to SSI. You will not, and will ensure that Authorized Users do not: (a) alter, copy,  modify, translate, or make derivative works of, or permit the alteration, copying, modification,  translation, or making derivative works of, the Services or any component thereof; (b) attempt to derive  the source code or object code for the Services, including by reverse engineering, decompiling,  disassembling, or similar means; (c) seek to acquire any ownership interest in or to the Services; (d)  license, offer, sell, transfer, or lease the Services or attempt any of the foregoing; (e) remove, alter, or  obfuscate any copyright, trademark, or other proprietary rights notices included with the Services; (f)  access or use the Services in order to design, develop, or build a similar product or competitive product;  (g) enable access to the Services by anyone not authorized to use the Services; (h) develop any scripts or  software applications that interact with or integrate with the Services unless first authorized in writing  by SSI; or (i) circumvent or modify any security technologies designed to prevent unauthorized access to  the Services. You will not frame or utilize framing techniques to enclose any trademark, logo, or other  proprietary information (including images, text, page layout, or form) of SSI without SSI&apos;s express  written consent. You will not use any meta-tags or any other &quot;hidden text&quot; utilizing any of SSI&apos;s names,  trademarks, or service marks without the express written consent of SSI.
            </p><br /><p>3.2 Acceptable Use. You will not use the Services, and will ensure that Authorized Users do not use the  Services, to: (a) infringe on, violate, dilute, or misappropriate the intellectual property rights, rights of  publicity, privacy rights, or other rights of any person; (b) engage in any fraudulent, unlawful, or abusive  activities; (c) store, send, or post defamatory, inflammatory, trade libelous, threatening, abusive,  hateful, harassing, obscene, pornographic, or indecent content or data; (d) interfere with or attempt to  interfere with or disrupt the integrity, security, functionality, or proper working of the Services or SSI provision of services to other customers; (e) attempt to discover, access, read, alter, destroy, or damage  any programs, data, or other information stored on or in connection with the Services; or (f) upload or  transmit any content that constitutes unsolicited or unauthorized advertising promotional materials,  commercial activities, or any other form of solicitation.
            </p><br /><br />
            <b>4. YOUR ENCRYPTED DATA</b><br />
            <p>4.1 Ownership; Encryption. You own and retain all right, title, and interest in and to all data and/or other  information transmitted, uploaded, or stored in association with your use of the Services (&quot;Your  Encrypted Data&quot;), including all intellectual property rights therein. You acknowledge and agree that (a)  you (not SSI) have control over Your Encrypted Data stored by operation of the Services and (b) you will  fully encrypt Your Encrypted Data (whether in transit, at rest, or otherwise) using the provided tools and services in your own computing environment (i) before transmitting Your Encrypted Data over the  Services and (ii) at all times while storing Your Encrypted Data in association with the Services.
            </p><br /><p>4.2 Use of Your Encrypted Data. SSI and its affiliates may use Your Encrypted Data only as necessary to  provide the Services, respond to support requests that you may initiate or otherwise communicate with  you, and/or as otherwise agreed to between the parties in writing.
            </p><br /><p>4.3 Rights in Your Encrypted Data. You represent and warrant to SSI that you have the rights, licenses, and  permissions necessary to transmit Your Encrypted Data over the Services. You will comply with all  applicable local, state, national, and foreign laws in connection with your use of the Services, including  those laws related to data privacy and the transmission of personal information. You will be solely  responsible for ensuring that any processing of Your Encrypted Data by SSI and/or you via the Services  does not violate any applicable laws. You acknowledge that SSI exercises no control over the content of  Your Encrypted Data. You will not upload, post, reproduce, or distribute any information, software, or  other material protected by copyright, privacy rights, or any other intellectual property rights without  first obtaining the permission of the owner of such rights.
            </p><br /><p>4.4 Compliance with Laws. You will comply with all applicable local, state, national, and foreign laws in  connection with your use of the Services. You acknowledge that all system hardware, system software,  proprietary data, know-how, or other data or information (herein referred to as &quot;Systems&quot;) obtained  from SSI may be subject to the import and/or export control laws of one or more countries and,  accordingly, their import, export, re-export, and transfer may be restricted or prohibited. You agree not  to, directly or indirectly, import, export, re-export, transfer, or cause to be imported, exported, re exported, or transferred, any such Systems to any destination, entity, or persons prohibited or restricted  under any law or regulation, unless you have first obtained prior written consent of SSI and any  applicable governmental entity, either in writing or as provided by applicable regulation, as the same  may be amended from time to time.
            </p><br /><br />
            <b>5. INTELLECTUAL PROPERTY RIGHTS</b><br />
            <p>5.1 Ownership by SSI. Subject to the use rights granted under these Terms, as between the parties, SSI owns and retains all right, title, and interest in and to the Services and any improvements, modifications,  enhancements, or derivatives of the foregoing, all work product (including any software) and  deliverables created, and all intellectual property rights relating to any of the foregoing. These Terms do  not convey to you any rights of ownership in or related to the Services, work product, or deliverables.  Except for the rights expressly granted in these Terms, no other rights are granted to you in, to, or under  SSI&apos;s intellectual property rights, whether by implication, estoppel, waiver, or otherwise.
            </p><br /><p>5.2 Metadata. Notwithstanding anything to the contrary in these Terms, you agree that SSI may generate,  collect, store, use, transfer, and/or disclose to third parties metadata gathered, prepared, computed,  originated, or stored by SSI resulting from the use or provision of the Services, including (a) size of Your  Encrypted Data stored, (b) throughput size and volume of Your Encrypted Data, and (c) other metadata  derived from or based on Your Encrypted Data (&quot;Metadata&quot;) (a) to perform data analytics, (b) to  monitor, improve, and support the Services, (c) to design, develop, and offer SSI products and services, and/or (d) for any other lawful purpose. SSI owns and retains all rights to Metadata, and no rights are
            granted to you, whether by implication, estoppel, waiver, or otherwise in or to any Metadata. SSI has no  obligation to provide or make any Metadata available to you.
            </p><br /><br />
            <b>6. TERM AND TERMINATION; SUBSCRIPTIONS</b><br />
            <p>6.1 Term. The term of these Terms commences on your acceptance of these Terms and, unless either party  terminates your access to the Services as set forth in this Section 6, continues until your access to the  Services terminates or expires (the &quot;Term&quot;).
            </p><br /><p>6.2 Recurring Subscriptions.
            <ul>
            <li>Your use of the Services may require enrollment in a payment plan involving automatic renewal  (a &quot;Recurring Subscription&quot;). If you enroll in a Recurring Subscription, you authorize us to  maintain your account information and charge that account automatically upon the renewal  with no further action required by you. The length of your Recurring Subscription will be  provided when you make your purchase. Your Recurring Subscription will automatically renew  unless you cancel it. If we are unable to charge your account as authorized by you when you  enrolled in a Recurring Subscription, we may in our sole discretion (a) bill you for the Services  and suspend your access to the Services until payment is received or (b) seek to update your  account information through third-party sources (i.e., your bank or a payment processor) to  continue charging your account as authorized by you.</li>
            <li>You may cancel your subscription through your account. You may cancel a Recurring  Subscription at any time, but if you cancel your Recurring Subscription before the end of the  current subscription period, we will not refund any subscription fees already paid to us.  Following any cancellation, however, you will continue to have access to the applicable Services  through the end of your current subscription period.</li>
            <li>We may change the prices charged for Recurring Subscriptions at any time by posting updated  pricing through the Services; provided, however, that the prices for your Recurring Subscription  will remain in force for the duration of the subscription period for which you have paid. After  that period ends, your use of the applicable Services will be charged at the then-current  subscription price. If you do not agree to these price changes, you must cancel your Recurring  Subscription. If you do not cancel, your Recurring Subscription will automatically renew at the  then-current price at the time of renewal and for the same duration as the initial subscription  term, and we will charge your on-file payment card or method on the first day of the renewal  of the subscription term.</li>
            </ul>
            </p><br /><p>6.3 Termination. You may terminate your access to the Services as set forth above. SSI may terminate your  access to the Services by notifying you in writing (with email notice being sufficient).
            </p><br /><p>6.4 Suspension. SSI may suspend or limit access to the Services at any time: (a) if SSI determines or  reasonably suspects that you are using the Services in violation of applicable law or in connection with  any fraudulent activity; (b) if SSI reasonably determines that your use of the Services adversely affects or  interferes with the normal operation of the Services or any service to others; (c) if SSI is prohibited by an  order of a court or other governmental agency from providing the Services; (d) if SSI reasonably believes  there exists a security incident that threatens the security of the Services, Your Encrypted Data, or any  data of others; or (e) for any other reason in SSI&apos;s reasonable discretion. SSI WILL HAVE NO LIABILITY  FOR ANY DAMAGES, LIABILITIES, OR LOSSES AS A RESULT OF ANY SUSPENSION OR LIMITATION OF YOUR  USE OF THE SERVICES IN ACCORDANCE WITH THIS PARAGRAPH.
            </p><br /><p>6.5 Effect of Expiration or Termination. Upon any expiration or termination of these Terms, all rights  granted to you under these Terms and SSI&apos;s obligations will immediately cease, and you will stop
            accessing or using the Services, except the following provisions will survive: Sections 3.1 (Restrictions),  4.1 (Ownership), 4.2 (Use of Your Encrypted Data), 5 (Intellectual Property Rights), 6.5 (Effect of  Expiration or Termination), 7 (Confidentiality), 8 (Indemnification), 9 (Disclaimers), 10 (Limitation of  Liability), 11 (Arbitration), and 12 (General Provisions).
            </p><br /><br />
            <b>7. CONFIDENTIALITY</b><br />
            <p>7.1 Protection. You may be exposed to or receive certain information that is not generally known to the  public and is marked as confidential or proprietary, or which, under the circumstances ought to be  treated as confidential (&quot;Confidential Information&quot;). You agree that if you are exposed to or receive  Confidential Information, you: (a) will protect Confidential Information from unauthorized disclosure  using at least a commercially reasonable degree of care; (b) will not disclose Confidential Information to  any third party; and (c) will not use the Confidential Information for any purpose.
            </p><br /><p>7.2 Return. After any expiration or termination of these Terms, or at any time upon request from SSI, you  will immediately return or destroy (at SSI&apos;s sole direction) all materials or media containing any  Confidential Information, including all copies thereof, and will certify in writing to SSI that all such  Confidential Information has been returned or destroyed.
            </p><br /><p>7.3 Injunctive Relief. You expressly acknowledge and agree that no adequate remedy exists at law for an  actual or threatened breach of this Section 7 and that in such event SSI will be entitled to seek and  obtain immediate injunctive and other equitable relief, without waiving any other rights or remedies  available to it.
            </p><br /><p>7.4 Feedback. You may from time to time voluntarily provide suggestions, enhancements,  recommendations, requests for features or functionality, comments, or other feedback to SSI regarding  SSI and/or the Services (&quot;Feedback&quot;). Feedback, even if designated as &quot;confidential&quot; or &quot;proprietary&quot; by  you, will not create any confidentiality or other obligation for SSI, and you hereby assign to SSI all rights  (including intellectual property rights), title and interest in and to such Feedback. All Feedback is  Confidential Information.
            </p><br /><br />
            <b>8. INDEMNIFICATION</b><br />
            <p>You will indemnify and hold SSI and its affiliates, and its and their officers, employees, and agents  harmless against any damages, liabilities, losses, costs, or expenses (including reasonable attorneys&apos; fees) arising from or in connection with (a) your access to or use of the Services, (b) Your Encrypted  Data, (c) your breach or alleged breach of these Terms, and/or (d) your infringement, misappropriation,  or violation of any intellectual property rights, rights of publicity, privacy rights, or other rights of a third  party (each, an &quot;Indemnifiable Claim&quot;). Additionally, you will, at SSI&apos;s sole election, defend SSI from any  Indemnifiable Claims. If SSI directs you to defend an Indemnifiable Claim, then (i) SSI has the right to  approve the counsel you select to defend the Indemnifiable Claim and (ii) SSI may also have its own  counsel participate in the defense and settlement of the Indemnifiable Claim at your expense. SSI may  also exclusively retain control of the defense of an Indemnifiable Claim. You will not settle an  Indemnifiable Claim without SSI&apos;s written consent.
            </p><br /><br />
            <b>9. DISCLAIMERS</b><br />
            <p>THE SERVICES ARE PROVIDED &quot;AS IS,&quot; AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SSI HEREBY EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE  SERVICES AND SERVICE PROVIDERS, WHETHER STATUTORY, EXPRESS, IMPLIED, OR THROUGH A COURSE  OF DEALING, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,  TITLE, AND NON-INFRINGEMENT. SSI DOES NOT WARRANT, AND SPECIFICALLY DISCLAIMS, THAT THE  SERVICES WILL OPERATE UNINTERRUPTED, BE ERROR-FREE, OR THAT ALL DEFECTS WILL BE CORRECTED.
            SSI MAKES NO WARRANTY CONCERNING TIMELINESS, ACCURACY, PERFORMANCE, QUALITY,  RELIABILITY, OR COMPLETENESS OF ANY INFORMATION OR RESULTS OBTAINED OR DERIVED THROUGH  THE USE OF THE SERVICES. SSI DISCLAIMS ANY LIABILITY FOR UNAUTHORIZED ACCESS, USE, OR RELEASE  OF ANY OF YOUR ENCRYPTED DATA.
            </p><br /><br />
            <b>10. LIMITATION OF LIABILITY</b><br />
            <p>TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL (A)  SSI OR ANY OF ITS SERVICE PROVIDERS BE LIABLE TO YOU OR ANY THIRD PARTY FOR PERSONAL INJURY,  PROPERTY DAMAGE, ERROR OR INTERRUPTION OF USE, LOSS, INACCURACY, OR CORRUPTION OF DATA,  COVER, LOST PROFITS OR REVENUE, LOSS OF BUSINESS, OR ANY CONSEQUENTIAL, INDIRECT, SPECIAL,  EXEMPLARY, PUNITIVE, OR INCIDENTAL DAMAGES, REGARDLESS OF THE FORM IN WHICH THE ACTION IS  BROUGHT (INCLUDING NEGLIGENCE), ARISING OUT OF OR RELATING TO THE RELATIONSHIP BETWEEN  THE PARTIES (INCLUDING THESE TERMS), INCLUDING THE USE OR INABILITY TO USE THE SERVICES,  WHETHER OR NOT SSI HAS BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES, OR (B) SSI&apos;S TOTAL LIABILITY UNDER THESE TERMS, REGARDLESS OF LEGAL THEORY (INCLUDING NEGLIGENCE),  EXCEED, IN THE AGGREGATE FOR ALL CLAIMS, $50. MULTIPLE CLAIMS WILL NOT EXPAND THIS LIMIT.  THE PARTIES ACKNOWLEDGE THAT THIS SECTION 10 REFLECTS THE AGREED UPON ALLOCATION OF RISK  BETWEEN THE PARTIES AND THAT NEITHER PARTY WOULD ENTER INTO THESE TERMS WITHOUT THESE  LIMITATIONS ON ITS LIABILITY. THIS LIMITATION ON LIABILITY WILL APPLY DESPITE THE FAILURE OF  ESSENTIAL PURPOSE OF ANY LIMITED REMEDY SET FORTH IN THESE TERMS.
            </p><br /><br />
            <b>11. ARBITRATION</b><br />
            <p>PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU AND SSI TO ARBITRATE CERTAIN  DISPUTES AND CLAIMS AND LIMITS THE MANNER IN WHICH THE PARTIES CAN SEEK RELIEF FROM  EACH OTHER. ARBITRATION PRECLUDES YOU AND SSI FROM SUING IN COURT OR HAVING A JURY  TRIAL. YOU AND SSI AGREE THAT ARBITRATION WILL BE SOLELY ON AN INDIVIDUAL BASIS AND NOT  AS A CLASS ARBITRATION, CLASS ACTION, OR ANY OTHER KIND OF REPRESENTATIVE PROCEEDING. SSI AND YOU ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY.
            FOLLOW THE INSTRUCTIONS BELOW IF YOU WISH TO OPT OUT OF THE REQUIREMENT OF  ARBITRATION ON AN INDIVIDUAL BASIS. NO CLASS OR REPRESENTATIVE ACTIONS OR ARBITRATIONS  ARE ALLOWED UNDER THIS ARBITRATION AGREEMENT.
            </p><br />
            <p>11.1 Informal Dispute Resolution Prior to Arbitration. For any dispute or claim that you have against SSI,  that SSI has against you, or that you have or SSI has arising from or relating to these Terms, the Services,  or any aspect of the relationship between you and SSI as relates to these Terms, the Services, including  any privacy or data security claims (collectively, &quot;Claims,&quot; and each a &quot;Claim&quot;), you and SSI agree to  attempt to first resolve the Claim informally via the following process:
            </p>
            <ul>
                <li>If you assert a Claim against SSI, you will first contact SSI by sending a written notice of your  Claim (&quot;Claimant Notice&rsquo;) to SSI by certified mail addressed to Sovereign Systems, Inc., 1902  Lincoln Blvd., Ste. 711, Santa Monica, CA 90405 or by email to legal@blindinsight.com. The  Claimant Notice must (a) include your name, residence address, email address, and telephone  number, (b) describe the nature and basis of the Claim, and (c) set forth the specific relief  sought.</li>
                <li>If SSI asserts a Claim against you, SSI will first contact you by sending a written notice of SSI&apos;s  Claim (&quot;SSI Notice&quot;), and each of a Claimant Notice and SSI Notice, a &quot;Notice&quot;) to you via email  to the primary email address associated with your account. The SSI Notice must (a) include the name of a SSI contact and the contact’s email address and telephone number, (b) describe the  nature and basis of the Claim, and (c) set forth the specific relief sought.</li>
                <li>If you and SSI cannot reach an agreement to resolve the Claim within thirty (30) days after you  or SSI receives such a Notice, then either party may submit the Claim to binding arbitration as  set forth below. The statute of limitations and any filing fee deadlines shall be tolled for thirty  (30) days from the date that either you or SSI first send the applicable Notice so that the parties  can engage in this informal dispute-resolution process.</li>
            </ul>
            <p>11.2 Claims Subject to Binding Arbitration; Exceptions. Except for individual disputes that qualify for small  claims court and any disputes exclusively related to the intellectual property or intellectual property  rights of you or SSI, including any disputes in which you or SSI seek injunctive or other equitable relief  for the alleged unlawful use of your or SSI&apos;s intellectual property or other infringement of your or SSI’s  intellectual property rights (&quot;IP Claims&quot;), all Claims, whether based in contract, tort, statute, fraud,  misrepresentation, or any other legal theory, including Claims that are not related to intellectual  property or intellectual property rights but are jointly filed with IP Claims, that are not resolved in  accordance with Section 11.1 will be resolved by a neutral arbitrator through final and binding  arbitration instead of in a court by a judge or jury. Such Claims include, without limitation, disputes  arising out of or relating to interpretation or application of this arbitration provision, including the  enforceability, revocability, or validity of the arbitration provision or any portion of the arbitration  provision. The arbitrator will have the authority to grant any remedy or relief that would otherwise be  available in court.
            </p><br /><p>11.3 Federal Arbitration Act. These Terms affect interstate commerce, and the enforceability of this Section  11 will be substantively and procedurally governed by the Federal Arbitration Act, 9 U.S.C. § 1, et seq. (the &quot;FAA&quot;), to the extent permitted by law. As limited by the FAA, these Terms, and the AAA Rules (as  defined below), the arbitrator will have exclusive authority to make all procedural and substantive  decisions regarding any dispute and to grant any remedy that would otherwise be available in court,  including the power to determine the question of arbitrability.
            </p><br /><p>11.4 Arbitration Procedure. All Claims must be submitted to the American Arbitration Association (the  &quot;AAA&quot;) and will be resolved through binding arbitration before one arbitrator. The AAA administers  arbitration pursuant to the due process standards set forth by the AAA and rules set forth by the AAA.  The then-current version of the AAA’s Commercial Arbitration Rules and Mediation Procedures, which  are available on the AAA’s website (adr.org) (the &quot;AAA Rules&quot;), as amended by these Terms as follows,  will apply to any arbitration between you and SSI:
            </p>
            <ul>
                <li>YOU AND SSI AGREE THAT ANY ARBITRATION UNDER THESE TERMS WILL TAKE PLACE ON AN  INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED, AND YOU  AND SSI ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION. The  arbitrator may conduct only an individual arbitration and, except as described below for the  additional procedures to govern if twenty-five (25) or more similar or coordinated claims are  asserted against SSI or you by the same or coordinated counsel, may not consolidate more than  one individual’s claims, preside over any type of class or representative proceeding, or preside  over any proceeding involving more than one individual.</li>
                <li>Any in-person appearances will be held in Los Angeles, California.</li>
                <li>You and SSI agree to cooperate to seek from the arbitrator protection for any confidential,  proprietary, trade secret, or otherwise sensitive information, documents, testimony, and/or  other materials that might be exchanged or the subject of discovery in the arbitration. You and SSI agree to seek such protection before any such information, documents, testimony, and/or  materials are exchanged or otherwise become the subject of discovery in the arbitration.</li>
                <li>The arbitrator&rsquo;s decision will follow these Terms and will be final and binding. The arbitrator will  have authority to award temporary, interim or permanent injunctive relief or relief providing for  specific performance of these Terms, but only to the extent necessary to provide relief  warranted by the individual claim before the arbitrator. The award rendered by the arbitrator  may be confirmed and enforced in any court having jurisdiction thereof. Notwithstanding any of  the foregoing, nothing in these Terms will preclude you from bringing issues to the attention of  federal, state or local agencies and, if the law allows, they can seek relief against SSI for you.</li>
                <li>The AAA Supplementary Rules for Multiple Case Filings and the AAA Multiple Consumer Case  Filing Fee Schedule will apply if twenty-five (25) or more similar claims are asserted against SSI or against you by the same or coordinated counsel or are otherwise coordinated.</li>
            <ul>
                <li>In addition to the application of the AAA Supplementary Rules for Multiple Case Filings  and the AAA Multiple Consumer Case Filing Fee Schedule, you and SSI understand and  agree that when twenty-five (25) or more similar claims are asserted against SSI or you  by the same or coordinated counsel or are otherwise coordinated resolution of your or  SSI &apos;s Claim might be delayed.</li>
                <li>For such coordinated actions, you and SSI also agree to the following coordinated  bellwether process. Counsel for claimants and counsel for SSI shall each select ten (10)  cases (per side) to proceed first in individual arbitration proceedings. The remaining  cases shall be deemed filed for purposes of the statute of limitations but not for the  purpose of assessing AAA fees. No AAA fees shall be assessed in connection with those  cases until they are selected to proceed to individual arbitration proceedings as part of a  bellwether process. If the parties are unable to resolve the remaining cases after the  conclusion of the initial twenty (20) proceedings, each side shall select another ten (10)  cases (per side) to proceed to individual arbitration proceedings as part of a second  bellwether process.</li>
                <li>A single arbitrator shall preside over each case. Only one case may be assigned to each  arbitrator as part of a bellwether process unless the parties agree otherwise.</li>
                <li>This bellwether process shall continue, consistent with the parameters identified above,  until all the claims included in these coordinated filings, including your case, are  adjudicated or otherwise resolved.</li>
                <li>The statute of limitations and any filing fee deadlines shall be tolled for claims subject to  this bellwether process from the time the first cases are selected for a bellwether  process until the time your or SSI&apos;s case is selected for a bellwether process, withdrawn,  or otherwise resolved.</li>
                <li>A court shall have authority to enforce this paragraph and, if necessary, to enjoin the  mass filing or prosecution of arbitration demands against SSI or you.</li>
                </ul></ul>
                <p>11.5 One Year to Assert Claims. To the extent permitted by law, any Claim by you or SSI relating in any way  to these Terms, the Services, or any aspect of the relationship between you and SSI as relates to these  Terms or the Services, must be filed within one year after such Claim arises; otherwise, the Claim is  permanently barred, which means that you and SSI will not have the right to assert the Claim.
                </p><br /><p>11.6 Opting Out of Arbitration. You have the right to opt out of binding arbitration within 30 days of the date  you first accepted these Terms by providing SSI with notice of your decision to opt-out via email at  legal@blindinsight.com or by certified mail addressed to Sovereign Systems, Inc., 1902 Lincoln Blvd., Ste.  711, Santa Monica, CA 90405. In order to be effective, the opt-out notice must include your full name,  mailing address, and email address. The notice must also clearly indicate your intent to opt out of  binding arbitration. By opting out of binding arbitration, you are agreeing to resolve disputes in  accordance with Section 12.9.
                </p><br /><p>11.7 Rejection of Future Arbitration Changes. You may reject any change we make to Section 11 (except  address changes) by personally signing and sending SSI a notice within 30 days of the change via email at  legal@blindinsight.com or by certified mail addressed to Sovereign Systems, Inc., 1902 Lincoln Blvd., Ste.  711, Santa Monica, CA 90405. If you do, the most recent version of Section 11 before the change you  rejected will apply.
                </p><br /><p>11.8 Severability. If any portion of this Section 11 is found to be unenforceable or unlawful for any reason,  including but not limited to because it is found to be unconscionable: (a) the unenforceable or unlawful  provision will be severed from these Terms; (b) severance of the unenforceable or unlawful provision  will have no impact whatsoever on the remainder of this Section 11 or the parties’ ability to compel  arbitration of any remaining claims on an individual basis pursuant to this Section 11; and (c) to the  extent that any claims must therefore proceed on a class, collective, consolidated, or representative  basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration. The  litigation of those claims will be stayed pending the outcome of any individual claims in arbitration.  Further, if any part of this Section 11 is found to prohibit an individual claim seeking public injunctive  relief, that provision will have no effect to the extent such relief is allowed to be sought out of  arbitration, and the remainder of this Section 11 will be enforceable.
                </p><br /><p>11.9 Disputes Outside the United States. Notwithstanding any terms to the contrary in these Terms, if you  reside in any country outside of the United States, you may bring legal proceedings regarding these Terms either by following the arbitration procedure detailed above in this Section 11 or, if given the  right by applicable law, by submitting the dispute to an arbitration administrator in the jurisdiction in  which you reside. To the extent any proceeding is not subject to arbitration under applicable law, you  may submit the dispute to the courts of the jurisdiction in which you reside.
            </p><br /><br />
            <b>12. GENERAL PROVISIONS</b><br />
            <p>12.1 Entire Agreement. These Terms constitute the entire understanding of the parties with respect to their subject matter and supersede all prior or contemporaneous proposals, understandings, and  agreements. If you provide SSI with any pre-printed terms and conditions that appear on any purchase  order or other form document, such terms will be of no force or effect.
            </p><br /><p>12.2 Assignment. You may not assign or transfer these Terms or any of your rights or obligations under it  without SSI&apos;s prior written consent. SSI may freely assign these Terms, including to its affiliates. Any  attempted assignment in violation of this paragraph will be null and void. Subject to the foregoing, these  Terms are binding upon and inures to the benefit of the parties and their respective successors and  permitted assigns.
            </p><br /><p>2.3 Severability. If a court finds any term of these Terms, other than Section 11, to be invalid or  unenforceable, that term will be enforced to the maximum extent permissible so as to reflect the  parties’ intent, and the remainder of these Terms will remain in full force and effect.
            </p><br /><p>12.4 Waiver. Either party&apos;s delay or failure to exercise any right under these Terms or any law does not mean  a party waives that right or any other rights under these Terms in the future. No waiver of any provision
            of these Terms, or any rights or obligations of either party under these Terms, will be effective except  pursuant to a written instrument signed by the party against whom the waiver is sought.
            </p><br /><p>12.5 Use of Name and Logo. Notwithstanding any terms to the contrary in this Agreement, you consent to  SSI&apos;s use of your name and logo on SSI&apos;s website and on SSI&apos;s promotional and marketing related  materials, identifying you as a customer of SSI and describing your use of the Services.
            </p><br /><p>12.6 Independent Contractors. Nothing contained in these Terms will be construed to create a joint venture  or partnership between the parties. Neither party is authorized as an agent or legal representative of  the other party. Neither party will have the right or authority to bind or create any obligation on the  other party.
            </p><br /><p>12.7 Force Majeure. SSI is excused from performance of these Terms and will not be liable for any delay in  whole or in part caused by any event outside of its control.
            </p><br /><p>12.8 No Third-Party Beneficiary. Nothing contained in these Terms will be deemed to create, or be construed  as creating, any third-party beneficiary right of action upon any third party in any manner whatsoever.
            </p><br /><p>12.9 Governing Law and Venue. These Terms will be governed in all respects in accordance with the laws of  the State of California, without regard to conflict of law principles that would cause the laws of any  other jurisdiction to apply. Except as set forth in Section 11, you expressly agree that federal and state  courts located in Los Angeles, California will have exclusive jurisdiction over any action or claim that you  bring that arises out of or relating to these Terms. You expressly consent to personal jurisdiction in any  such court and hereby irrevocably waive any objection to or claim of lack of jurisdiction or forum non  conveniens.
            </p><br /><p>12.10 Interpretation. The headings of these Terms are for reference only and will not be used to interpret the  meaning of these Terms. Any reference to &quot;includes&quot; or &quot;including&quot; will be understood to be exemplary  and not limiting and followed by &quot;but not limited to.&quot; Each party has had the opportunity to review  these Terms with legal counsel, and there will be no presumption that ambiguities will be construed or  interpreted against the drafter.
           </p></div> </>)
}
