import process from "process"
import React from "react"
import Box from "@mui/material/Box"

export default function Debug() {
    return (
        <Box>
            <Box><h1>Debug</h1></Box>
            <Box>BLINDINSIGHT_PROXY_URL: {process.env.BLINDINSIGHT_PROXY_URL}</Box>
        </Box>
    )
}
