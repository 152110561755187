import React from "react"
import { Button } from "../components/Button"
import TextField from "../components/TextField"
import "./OrgName.css"

export const SelectOrgName = () => {
    return (
      <div className="container dark">
      <div className="section">
      <div className="message">
            <h1>Complete registration</h1>
            <p>
              Add your full name and organization name so we can get your account setup. <br />
              <br />
              If you have any questions you can always reach out through the Intercom messenger at the bottom right of
              your screen, or see our <a href="https://docs.blindinsight.io/getting-started/" rel="noopener noreferrer" target="_blank"> Getting Started Guide.</a>
            </p>
          </div>
          <div className="form-container">
              <div className="logo-vertical" />
              <div className="sign-in-form">
                Enter your organization and personal info to complete sign-up and access your account.
                <TextField
                  className="text-field-instance full-width"
                  size="large"
                  stateProp="active"
                  type="text"
                  validation={false}
                  placeholder="Organization Name"
                />
                <TextField
                  className="text-field-instance full-width"
                  size="large"
                  stateProp="active"
                  type="text"
                  validation={false}
                  placeholder="Your Full Name"
                />
           <a href="/quickstart"><Button
  buttonTextClassName="design-component-instance-node"
  className="light"
  size="large"
  stateProp="active"
  text="Let’s Go!"
/></a>
            </div>
          </div>
        </div>
      </div>
  )
}
