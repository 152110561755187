import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Routes from './App'
import reportWebVitals from './reportWebVitals'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

const router = createBrowserRouter(Routes)
const root = ReactDOM.createRoot(document.getElementById('root'))

/*
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
*/
root.render(
  <RouterProvider router={router} />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
window.Intercom("boot", {
  api_base: "https://api-iam.intercom.io",
  app_id: "gldix7ww",
  name: sessionStorage.getItem('auth.username'), // Full name
  email: sessionStorage.getItem('auth.username'), // the email for your user
  //created_at: "user.createdAt", // Signup date as a Unix timestamp
})

window.Intercom("update")
