import React, { useState } from "react"
import { Tabs, Tab } from "@mui/material"
import { Link, useRouteLoaderData, Form } from "react-router-dom"
import { Button } from "../components/Button"
import { CurrentOrg } from "../lib/store"
import TextField from "../components/TextField"

export default function Account() {
  let [editMode, setEditMode] = useState("")
  const [currentOrg, setCurrentOrg] = CurrentOrg.useState()
  const user = useRouteLoaderData("auth")


  return (<>
    <Tabs value="account">
      <Tab
        label="Account"
        value="account"
        disabled

      />
       <Tab
                    label="Users"
                    value="users"
                    component={Link}
                    to="/users"
    />
      <Tab
        label="Profile"
        value="profile"
        component={Link}
        to="/profile"
      />
    </Tabs>
    <div className="view rows">
      <h2>Account settings</h2>
      <div className="info-groups columns">
        <div className="rows">
          <div className="info-group rows">
            <h6>Organization name</h6>
            <div className="columns">
            {editMode === "orgname" &&
                (<>
                  <Form className="columns">
                    <TextField
                      name='orgName'
                      placeholder={currentOrg.name}
                      value={currentOrg.name}
                      onChange={setCurrentOrg.name}
                      size="small"
                      type="text"
                      required
                    />
                    <Button
                      icon="save"
                      className="status-green"
                      size="small"
                      buttonStyle="inline"
                      type="submit"
                      onClick={() => {
                        setEditMode('')
                      }} />
                  </Form>
                </>) ||
                (<>
                  {currentOrg.name}
                  <Button
                    icon="edit"
                    size="small"
                    buttonStyle="inline"
                    text="edit"
                    onClick={() => {
                      setEditMode('orgname')
                    }} />
                </>)}
            </div>
          </div>
          <div className="info-group rows">
            <h6>Subdomain (slug)</h6>
            <div className="columns">
              {editMode === "slug" &&
                (<>
                  <Form className="columns">
                    <TextField
                      name='slug'
                      placeholder={currentOrg.slug}
                      value={currentOrg.slug}
                      onChange={setCurrentOrg.slug}
                      size="small"
                      type="text"
                      required
                    />
                    .blindinsight.io
                    <Button
                      icon="save"
                      size="small"
                      buttonStyle="inline"
                      type="submit"
                      onClick={() => {
                        setEditMode('')
                      }} />
                  </Form>
                </>) ||
                (<>
                  {currentOrg.slug}.blindinsight.io
                  <Button
                    icon="edit"
                    size="small"
                    buttonStyle="inline"
                    text="edit"
                    onClick={() => {
                      setEditMode('slug')
                    }} />
                </>)}
            </div>
          </div>
          <div className="info-group rows">
            <h6>SUBSCRIPTION TYPE</h6>
            <div className="columns">Free (Beta)<Button buttonStyle="inline" size="small" text="upgrade" icon="upgrade" onClick={() => {
              setEditMode('sub')
            }} /></div>
          </div>
        </div>
        {editMode === "sub" &&
        <div className="column manage-subscription">
          <h6>Add/change subscription</h6>
          <stripe-pricing-table
            pricing-table-id="prctbl_1PGTbB2LBQb5RXBfGOIPxflu"
            publishable-key="pk_live_51OyyPU2LBQb5RXBfdllEfqqHx276ACzNLXNrrFePPQadx5C0LEsDQdeHsY7dP2602KSO7RkxnxDB9GFPeEg79EdW00IQbe4i6j"
            custom-email={user.email}>
          </stripe-pricing-table>
        </div>
      }
      </div>

    </div>
  </>
  )
}
