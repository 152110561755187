import React from "react"
import { Outlet } from "react-router-dom"

import Box from "@mui/material/Box"

import SchemaSideBar from "./Sidebar"

export default function SidebarLayout() {
    return (
      <Box display="flex" height="100vh">
        <SchemaSideBar />
        {/* This should be a Box probably, and display=flex, otherwise it can collide with the sidebar */}
        <div className="main">
            <Outlet />
        </div>
    </Box>
    )
}
