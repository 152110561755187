import LinkHeader from 'http-link-header'
import Cookies from 'js-cookie'
import {
    CurrentOrg,
    CurrentOrgId,
    CurrentUser,
    Datasets,
    DatasetSchemas,
    HashedSchemas,
    Schemas,
} from "./store"
import env from "./env"
import process from "process"

// TODO(shakefu): Hoist these to a constants.js?
export const AUTH_EMAIL = "auth.username"
export const AUTH_ORGANIZATION_ID = "auth.organization_id"

// TODO(shakefu): Make this work more better
// This environment variable has NO EFFECT in local development, it's only used
// by the Docker entrypoint script within the built container
const defaultApi = (
    process.env.BLINDINSIGHT_PROXY_URL || env.BLINDINSIGHT_PROXY_URL
)

// The default amoutn of items to return unless otherwise specified by a caller.
const DEFAULT_LIMIT = 50
const DEFAULT_OFFSET = 0


function getIdFromUrl(url) {
    const parts = url.replace(/\/+$/, "").split("/")
    return parts.pop()
}

function updateDatasetSchemas(data) {
    // Create a mapping of datasetId to [schemaIds]
    let datasetMap = {}
    for (let schema of data) {
        if (!schema.dataset) {
            continue
        }
        let datasetId = getIdFromUrl(schema.dataset)
        if (!datasetMap[datasetId]) {
            datasetMap[datasetId] = []
        }
        datasetMap[datasetId].push(schema.id)
    }

    // Update the datasetSchemas state
    DatasetSchemas.updateValue((datasetSchemas) => {
        for (let datasetId in datasetMap) {
            if (!datasetSchemas[datasetId]) {
                datasetSchemas[datasetId] = datasetMap[datasetId]
                continue
            }
            datasetSchemas[datasetId] = [...new Set([...datasetSchemas[datasetId], ...datasetMap[datasetId]])]
        }
    })
}

export class Api {
    constructor(options) {
        this.api = options?.api || defaultApi
        for (let key in options) {
            if (!this[key]) {
                throw new Error(`${key} is not a valid option`)
            }
            if (!options[key]) {
                delete options[key]
            }
            this[key] = options[key]
        }
    }

    getAuthUsername() {
        return sessionStorage.getItem(AUTH_EMAIL)
    }

    async fetch(path, options = {}) {
        let uri = `${this.api}${path}`

        // Set the default headers and any provided by caller.
        options.headers = {
            "Content-Type": "application/json",
            ...options.headers,
        }

        // Pass along the CSRF token if it exists.
        const csrftoken = Cookies.get("csrftoken") || null
        if (csrftoken) {
            console.debug("CSRF: Token found", csrftoken)
            options.headers["X-CSRFToken"] = csrftoken
        } else {
            console.debug("CSRF: No token found")
        }

        // Handle query parameters by merging existing params and ovewriting
        if (options.params) {
            let url = new URL(uri)
            let params = {}
            for (const [key, value] of url.searchParams) {
                params[key] = value
            }
            for (const [key, value] of Object.entries(options.params)) {
                params[key] = value
            }
            url.search = new URLSearchParams(params).toString()
            uri = url.toString()
        }

        // Always pass through cookies to the API.
        options.credentials = "include"

        console.debug("API call", uri, options)
        const resp = await fetch(uri, options)
        console.debug("API response", uri, resp?.status)

        if (resp && !resp.ok) {
            if (resp.status > 399) {
                console.error("Api call failed", uri, resp.status, resp.statusText)
            }
            throw resp
        } else if (!resp) {
            throw new Response("Unknown error", { status: 500 })
        }

        return resp
    }

    async call(path, options = {}, callback) {
        const resp = await this.fetch(path, options)
        const data = await resp.json()
        const linkHeader = resp.headers.get("Link")
        const countHeader = resp.headers.get("X-Total-Count")

        /// Add data.links if set
        if (linkHeader) {
            const links = LinkHeader.parse(linkHeader)
            Object.defineProperty(data, "links", {
                value: {
                    first: links.rel("first")[0]?.uri,
                    next: links.rel("next")[0]?.uri,
                    last: links.rel("last")[0]?.uri,
                },
                writable: false,
                enumerable: false,
            })
        }

        /// Add data.count if set
        if (countHeader) {
            Object.defineProperty(data, "count", {
                value: parseInt(countHeader),
                writable: false,
            })
        }

        if (callback) callback(data)
        return data
    }

    setOrFindOrganization(organizations) {
        if (!organizations?.length) {
            console.log("No organizations")
            return
        }
        let currentOrgId = sessionStorage.getItem(AUTH_ORGANIZATION_ID)
        if (!currentOrgId) {
            console.log("No organization set in session, defaulting to first")
            currentOrgId = organizations[0].id
            sessionStorage.setItem(AUTH_ORGANIZATION_ID, currentOrgId)
        }

        let currentOrg = organizations.find(org => org.id === currentOrgId)
        if (!currentOrg) {
            console.log("Organization not found, defaulting to first")
            currentOrgId = organizations[0].id
            sessionStorage.setItem(AUTH_ORGANIZATION_ID, currentOrgId)
            currentOrg = organizations[0]
        }

        // Update state
        CurrentOrg.setValue(currentOrg)
        CurrentOrgId.setValue(currentOrgId)
    }

    clearAuth() {
        sessionStorage.removeItem(AUTH_EMAIL)
        sessionStorage.removeItem(AUTH_ORGANIZATION_ID)
    }

    async accountsLogin(email, password) {
        const options = {
            method: "POST",
            body: JSON.stringify({ login: email, password: password })
        }
        return await this.fetch("/api/accounts/login/", options)
    }

    async accountsLogout() {
        const options = {
            method: "POST",
        }
        const resp = await this.fetch("/api/accounts/logout/", options)
        this.clearAuth()
        return resp
    }

    async accountsRegister(email, password, fullName, organizationName) {
        console.log("Api.accountsRegister", email, password, fullName, organizationName)
        console.log("Full name", fullName)
        const nameParts = fullName.split(" ")
        console.log("Name parts", nameParts)
        let firstName = nameParts[0]
        console.log("First name", firstName)
        let lastName = nameParts.slice(1).join(" ")
        console.log("Last name", lastName)
        const options = {
            method: "POST",
            body: JSON.stringify({
                 email: email,
                 first_name: firstName,
                 last_name: lastName,
                 organization_name: organizationName,
                 password: password,
                 password_confirm: password,
            })
        }
        return await this.call("/api/accounts/register/", options)
    }

    async accountsVerifyRegistration(user_id, timestamp, signature) {
        console.log("Activating user", user_id, timestamp, signature)
        const options = {
            method: "POST",
            body: JSON.stringify({ user_id, timestamp, signature })
        }
        return await this.call("/api/accounts/verify-registration/", options)
    }

    async accountsSendPasswordResetLink(email) {
        console.log("Sending password reset link to", email)
        const options = {
            method: "POST",
            body: JSON.stringify({ email })
        }
        return await this.call("/api/accounts/send-reset-password-link/", options)
    }

    async accountsResetPassword(user_id, timestamp, signature, password) {
        console.log("Resetting password for", user_id, timestamp, signature)
        const options = {
            method: "POST",
            body: JSON.stringify({ user_id, timestamp, signature, password })
        }
        return await this.call("/api/accounts/reset-password/", options)
    }

    async datasetsRetrieve(id) {
        const data = await this.call(`/api/datasets/${id}/`)
        if (!data) {
            console.error("No dataset found with id", id)
            return null
        }
        Datasets.updateValue((datasets) => datasets[id] = data)
        return data
    }

    async datasetsList(organizationId, limit=DEFAULT_LIMIT, offset=DEFAULT_OFFSET) {
        const options = {
            params: {
                limit,
                offset,
            }
        }
        if (organizationId) options.params.organization = organizationId

        const data = await this.call("/api/datasets/", options)

        if (!data) {
            throw new Response("Datasets not found", { status: 404 })
        }
        Datasets.updateValue((datasets) => {
            for (let dataset of data) {
                datasets[dataset.id] = dataset
            }
        })
        return data
    }

    async datasetsCreate(name, description, orgUrl) {
        const options = {
            method: "POST",
            body: JSON.stringify({ name, description, organization: orgUrl }),
        }
        console.log("Creating dataset", name, description)
        return await this.call("/api/datasets/", options)
    }

    async schemasCreate(datasetUrl, name, description, schema) {
        const options = {
            method: "POST",
            body: JSON.stringify({ dataset: datasetUrl, name, description, schema }),
        }
        console.log("Creating schema", name, description)
        return await this.call("/api/schemas/", options)
    }

    async schemasRetrieve(id) {
        const data = await this.call(`/api/schemas/${id}/`)
        if (!data) {
            throw new Response("Schema not found", { status: 404 })
        }
        Schemas.updateValue((schemas) => schemas[id] = data)

        if (!data.id || !data.dataset) {
            console.error("No dataset found for schema", id)
            return data
        }

        updateDatasetSchemas([data])

        return data
    }

    async schemasList(datasetId, limit=DEFAULT_LIMIT, offset=DEFAULT_OFFSET) {
        const options = {
            params: {
                limit,
                offset
            }
        }
        if (datasetId) options.params.dataset = datasetId
        const data = await this.call("/api/schemas", options)

        if (!data) {
            throw new Response("Schemas not found", { status: 404 })
        }

        // Update the schemas state
        Schemas.updateValue((schemas) => {
            for (let schema of data) {
                schemas[schema.id] = schema
            }
        })

        updateDatasetSchemas(data)

        return data
    }

    async schemasDecrypt(schema) {
        if (!schema) return null
        console.debug("Decrypting schema", schema.id)

        const options = { "method": "POST", "body": JSON.stringify(schema) }
        const data = await this.call("/api/schemas/decrypt/", options)
        if (!data) {
            console.error("Schema decryption failed", schema)
            return null
        }
        HashedSchemas.updateValue((hashedSchemas) => hashedSchemas[schema.id] = data)

        return data
    }

    async recordsList(schemaId, limit=DEFAULT_LIMIT, offset=DEFAULT_OFFSET) {
        if (!schemaId) {
            throw new Response("No schema provided", { status: 400 })
        }

        const options = {
            params: {
                schema: schemaId,
                limit,
                offset
            }
        }
        return await this.call("/api/records/", options)
    }

    async recordsSearch(schemaId, filters, limit=DEFAULT_LIMIT, offset=DEFAULT_OFFSET) {
        if (!schemaId) {
            throw new Response("No schema provided", { status: 400 })
        }

        const options = {
            method: "POST",
            body: JSON.stringify({
                schema: schemaId,
                filters,
                limit,
                offset
            }),
        }

        // Try to search; fallback to list.
        try {
            return await this.call("/api/records/search/", options)
        } catch (resp) {
            if (resp.status === 405) {
                console.error("Proxy not inline; falling back to recordsList")
                return await this.recordsList(schemaId, limit, offset)
            }

            console.error("Failed to search records", resp)
            throw resp
        }
    }

    async recordsDecrypt(records) {
        if (!records || !records.length) return []

        const options = { "method": "POST", "body": JSON.stringify(records) }
        return await this.call("/api/records/decrypt/", options)
    }

    async usersByNameRetrieve(name) {
        console.log("Retrieving user by name", name)
        return await this.call(`/api/users/by_name/${name}/?expand=organizations.organization.organization_user.user`)
    }

    async usersSelfRetrieve() {
        console.log("Retrieving self")
        // Expand the user's organizations, owner, and organization members
        return await this.call(`/api/users/self/?expand=organizations.organization_users.user,organizations.owner.organization_user.user`)
    }

    setAuth({ email }) {
        console.log("setAuth: Setting auth", email)
        if (email) sessionStorage.setItem(AUTH_EMAIL, email)
    }

    async currentUserRetrieve() {
        const user = CurrentUser.getValue() || {}
        const username = user?.username || this.getAuthUsername()
        console.debug("Retrieving currentUser", username)

        if (!username) {
            console.error("No user logged in")
            // return null
        }
        // const data = await this.usersByNameRetrieve(username)
        const data = await this.usersSelfRetrieve()
        console.log("Retrieved currentUser DATA=", data)
        if (!data?.email) {
            console.error("No user found with name", username)
            return data
        }
        this.setAuth(data)
        this.setOrFindOrganization(data.organizations)
        CurrentUser.setValue(data)
        return data
    }

    async organizationsList(limit=DEFAULT_LIMIT, offset=DEFAULT_OFFSET) {
        const options = {limit, offset}
        return await this.call("/api/organizations/", options)
    }

    async organizationsRetrieve(id) {
        return await this.call(`/api/organizations/${id}/`)
    }

    async organizationsCreate(name) {
        const options = {
            method: "POST",
            body: JSON.stringify({ name }),
        }
        return await this.call("/api/organizations/", options)
    }

    async organizationsUpdate(id, name, slug) {
        let payload = {}

        if (name) payload.name = name
        if (slug) payload.slug = slug

        const options = {
            method: "PUT",
            body: JSON.stringify(payload),
        }
        return await this.call(`/api/organizations/${id}/`, options)
    }

    async organizationsUsersList(orgId, limit=DEFAULT_LIMIT, offset=DEFAULT_OFFSET) {
        const options = {limit, offset}
        return await this.call(`/api/organizations/${orgId}/users/?expand=user`, options)
    }

    async organizationsUsersPartialUpdate(orgId, userId, isAdmin) {
        return await this.call(`/api/organizations/${orgId}/users/${userId}/`, {
            method: "PATCH",
            body: JSON.stringify({ is_admin: isAdmin}),
        })
    }

    async organizationsInvitationsCreate(orgId, email, isAdmin) {
        return await this.call(`/api/organizations/${orgId}/invitations/`, {
            method: "POST",
            body: JSON.stringify({ email, is_admin: isAdmin }),
        })
    }

}
