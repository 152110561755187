import React from "react"
import "./Icon.css"
import PropTypes from "prop-types"
    export const Icon = props => (
          <div className={`icon icon-${[props.type]} ${[props.className]} ${[props.size]}`}></div>
      )

      Icon.propTypes = {
        type: PropTypes.string.isRequired,
      }
