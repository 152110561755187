import React from "react"
import { Route, Navigate, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType, createRoutesFromElements } from "react-router-dom"
import Account from "./views/Account"
import AuthRequired from "./components/AuthRequired"
import { AuthRequiredError } from "./components/AuthRequired"
import Debug from "./components/Debug"
import SidebarLayout from "./components/SidebarLayout"
import FetchDatasetsSchemas from "./components/FetchDatasetsSchemas"
import CurrentOrganization from "./components/CurrentOrganization"
import Profile from "./views/Profile"
import Users from "./views/Users"

// import Home from "./views/Home"
import AccountsActivate from "./views/AccountsActivate"
import ForgotPassword from "./views/ForgotPassword"
import ResetPassword from "./views/ResetPassword"
import RegisterConfirm from "./views/RegisterConfirm"
import DatasetsList from "./views/DatasetsList"
import DatasetDetail from "./views/DatasetDetail"
import SchemaDetail from "./views/SchemaDetail"
import SchemaCreate from "./views/SchemaCreate"
import SchemaRecordsList from "./views/SchemaRecordsList"
import { SelectOrgName } from "./views/OrgName"
import { QuickStart } from "./views/QuickStart"
import PageNotFound from "./views/PageNotFound"
import * as Sentry from "@sentry/react"

const SENTRY_DSN = process.env.BLINDINSIGHT_UI_SENTRY_DSN

// Only initialize Sentry if the DSN is set.
if (SENTRY_DSN) {

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
      }),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/.*\.blindinsight\.io\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

}

const App = createRoutesFromElements(
  <Route exact path="/">
    <Route path = "*"
      element={<PageNotFound />}
    />
    <Route
      id="auth"
      element={<AuthRequired />}
      loader={AuthRequired.loader}
      errorElement={<AuthRequiredError />}
    >
      <Route index element={<Navigate to="/datasets" />} />
      <Route
        element={<CurrentOrganization />}
        loader={CurrentOrganization.loader}
      >
        <Route
          element={<FetchDatasetsSchemas />}
          loader={FetchDatasetsSchemas.loader}
        >
          <Route element={<SidebarLayout />}>
            <Route path="datasets">
              <Route index element={<DatasetsList />} />
              <Route path=":datasetId">
                <Route index element={<DatasetDetail />} />
                <Route exact path="schemas">
                  <Route index element={<Navigate to="/datasets/:datasetId" />} />
                  <Route exact path="create">
                      <Route index element={<SchemaCreate />} />
                    </Route>
                  <Route
                    id="schema"
                    path=":schemaId"
                  >

                    <Route index element={<SchemaDetail />} />
                    <Route exact path="records">
                      <Route index element={<SchemaRecordsList />} />
                    </Route>

                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="account" element={<Account />} />
            <Route path="users" element={<Users />} />
            <Route path="profile" element={<Profile />} />

          </Route>
        </Route>
        <Route path="debug" element={<Debug />} />
        {/* The orgname and quickstart pages should not be at the top level - probably under /register or /signup */}
        <Route path="orgname" element={<SelectOrgName />} />
        <Route path="quickstart" element={<QuickStart />} />
      </Route>
    </Route>
    <Route path="accounts">
      <Route path="activate" element={<AccountsActivate />} />
      <Route path="register-confirm" element={<RegisterConfirm />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Route>
  </Route>
)

export default App
