export function isEmpty(value) {
    return value && value.constructor === Object && Object.keys(value).length === 0
}

export function onChange(fn) {
  return (e) => {
    return fn(e.target.value)
  }
}

// TODO(shakefu): samesies
export function onEnter(fn) {
  return (e) => {
    if (e.key !== "Enter") return
    fn()
  }
}
