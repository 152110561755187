import React, { useMemo } from "react"
import { Alert, Box } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import Modal from "./Modal"
import datasetCard from "../images/dataset-card.svg"
import TextField from "../components/TextField"
import { Button } from "../components/Button"
import { Api } from "../lib/api"
import { DatasetSchemas, CurrentOrg } from "../lib/store"

export default function DatasetCard({ dataset }) {
  const [datasetSchemas] = DatasetSchemas.useState()
  const [currentOrg] = CurrentOrg.useState()
  const count = useMemo(
    () => datasetSchemas[dataset.id]?.length || 0,
    [datasetSchemas, dataset]
  )
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(null)
  const api = new Api()
  const navigate = useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault()
    const { name, description } = event.target.elements
    try {
      const response = await api.datasetsCreate(name.value, description.value, currentOrg.url)
      setOpen(false)
      setError(null)
      await api.datasetsList(currentOrg.id)
      console.log("Created dataset", response)
      navigate(`/datasets/${response.id}`)
    } catch (e) {
      console.log("Failed to create dataset", e)
      setError((await e.json()).detail)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setError(null)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  if (dataset === "default") {
    return (
      <>
        <div className="card-img">
          <Box width="90px" height="90px" component="img" src={datasetCard} />
        </div>
        <Box m={2}>
          <div className="full-width center">
            <Button type="button" buttonStyle="square" size="small" onClick={handleOpen} text="Create new" icon="add" id="btn-dataset-create" />
          </div>

          <Modal
            isOpen={open}
            onClose={handleClose}
            heading="Create a new dataset"
          >
            <div className="column">
              {error && <Alert severity="error">{error}</Alert>}
              <form onSubmit={handleSubmit} className="rows">
                <TextField
                  name="name"
                  className="text-field"
                  required
                  placeholder="Name"
                  helperText="Give your dataset a name"
                  id="ds-name"
                />
                <TextField
                  name="description"
                  className="text-field"
                  placeholder="Description (optional)"
                  id="ds-description"
                />

                <div className="form-action button-group">
                  <div className="button-container">
                    <Button
                      buttonStyle="inline"
                      size="small"
                      text="Cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      text="Create"
                      className="dark"
                      id="btn-ds-create-confirm"
                      type="submit"
                    >
                      Create
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </Box>
      </>
    )
  }

  return (
    <>
      <div className="card-img">
        <Box width="90px" height="90px" component="img" src={datasetCard} alt="dataset" />
      </div>
      <div className="card-content">
        <div className="card-title">
          <Link to={`/datasets/${dataset.id}`}>
            {dataset.name}</Link>
        </div>
        <small>{dataset.description}</small>
        <div className="card-footer">
        <small>{count} schema{count > 1 ? "s" : ""}</small>
      </div>
        </div>

    </>
  )
}
