import React from "react"
import { useRouteLoaderData } from "react-router-dom"

// import ButtonBase from "@mui/material/ButtonBase";

// import addIcon from "../images/add-icon.svg";
import DatasetCard from "../components/DatasetCard"
import { Datasets } from "../lib/store"

export default function DatasetsList() {
  const user = useRouteLoaderData("auth")
  const [datasets,] = Datasets.useState()

  if (!user) return null

  return (
    <div className="view">
      <div className="rows">
        <h2>Datasets</h2>
        <div className="grid">
          <div className="card">
            <DatasetCard dataset="default" />
          </div>
          {Object.keys(datasets).length < 1 && (
            <div className="empty-state-help card">
              <div className="card-content">
                <h5>Welcome to the Blind Insight Beta!</h5>
                <p>
                  <small>
                    Start exploring the UI or check out the links below for the
                    full developer experience.
                  </small>
                </p>

                <h6>Developer Quickstart:</h6>
                <p>
                  <a href="https://docs.blindinsight.io/download/" target="new">
                    Download the Blind Proxy.
                  </a>
                </p>
                <p>
                  <a
                    href="https://github.com/blind-insight/demo-datasets/releases"
                    target="new"
                  >
                    Download a sample dataset, or use your own.
                  </a>
                </p>
                <p>
                  <a
                    href="https://docs.blindinsight.io/getting-started/"
                    target="new"
                  >
                    Check out the Getting Started Guide.
                  </a>
                </p>
              </div>
            </div>
          )}
          {Object.values(datasets).map((dataset, index) => (
            <div className="card" key={index}>
              <DatasetCard dataset={dataset} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
