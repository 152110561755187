import React from "react"
import { Form,} from "react-router-dom"
import { Api } from "../lib/api"
import { Button } from "../components/Button"
import TextField from "../components/TextField"
import { Alert } from '@mui/material'

import "../components/Login.css"

export default function ForgotPassword() {
    const [linkSent, setLinkSent] = React.useState(false)
    const [toEmail, setToEmail] = React.useState("")
    const api = new Api()

    const handlePasswordReset = (event) => {
        event.preventDefault()
        const email = event.target.email.value
        console.log("Resetting password for", email)
        const resetPassword = async () => {
            console.log("Sending password reset request")
            const resp = await api.accountsSendPasswordResetLink(email)
            return resp
        }
        setToEmail(email)
        const ok = Boolean(resetPassword())
        setLinkSent(ok)
    }

    return (
        <React.Fragment>
            <div className="container dark">
                <div className="section">
                    <div className="message">
                        <h1>Forgot your Blind Insight password?</h1>
                        <p>Enter your email and we will send you a link.</p>
                        {
                            linkSent &&
                            <Alert severity="success">
                                <p>Password reset link sent to <b>{toEmail}</b></p>
                                <p>Please check your email and click the link!</p>
                            </Alert>
                        }
                    </div>
                    <div className="form-container">
                        <div className="logo-vertical" />
                        <Form onSubmit={handlePasswordReset} className="full-width rows">
                            <TextField
                                className="full-width"
                                label="Email address"
                                id="email"
                                name="email"
                                placeholder="Email address"
                                size="small"
                                type="email"
                                required
                            />
                            <div className="button-group form-action row">
                                <Button size="small" text="Send Password Link" type="submit" className="light" />
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="footer dark full-width">
                    &copy; 2024, <a href="https://blindinsight.com">Blind Insight</a> | <a href="https://docs.blindinsight.io">View docs</a>
                </div>
            </div>
        </React.Fragment>
    )
}
