import React from "react"
import { Form, Link, useSearchParams } from "react-router-dom"
import { Api } from "../lib/api"
import { Button } from "../components/Button"
import TextField from "../components/TextField"
import { Alert } from '@mui/material'


export default function ResetPassword() {
    const [searchParams, ] = useSearchParams()
    const [resetSuccess, setResetSuccess] = React.useState(null)
    const [resetError, setResetError] = React.useState(null)
    const [error, setError] = React.useState(null)
    const api = new Api()

    const user_id = searchParams.get("user_id")
    const timestamp = searchParams.get("timestamp")
    const signature = searchParams.get("signature")

    React.useEffect(() => {
        if (!user_id || !timestamp || !signature) {
            setResetError("Invalid password reset link. Please request a new one.")
        }
    } , [user_id, timestamp, signature])

    const handlePasswordReset = (event) => {
        event.preventDefault()

        const password = event.target.password.value
        const password_confirm = event.target.password_confirm.value

        if (password !== password_confirm) {
            console.log("Passwords do not match")
            setError("Passwords do not match")
            return
        }

        const resetPassword = async () => {
            console.log("Sending password reset request")
            const resp = await api.accountsResetPassword(user_id, timestamp, signature, password)
            return resp
        }
        const resp = resetPassword()
        if (!resp) {
            console.log("Reset failed")
            setResetError(resp.detail)
            return
        }
        console.log("Reset success")
        resp.then((resp) => {
            setResetSuccess(getSuccessMessage(resp))
        })
    }

    const getSuccessMessage = (resp) => {
        return (
            <React.Fragment>
                <p>{resp.detail}. <Link to="/">Click here to login</Link></p>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="container dark">
                <div className="section">
                    <div className="message">
                        <h1>Reset your Blind Insight password</h1>
                        <p>Please select a new password.</p>
                        {
                            resetSuccess &&
                            <Alert severity="success">{resetSuccess}</Alert>
                        }
                        {
                            resetError &&
                            <Alert severity="error">{resetError}</Alert>
                        }
                    </div>
                    <div className="form-container">
                        <div className="logo-vertical" />

                        {error && <Alert severity="error">{error}</Alert>}

                        <Form onSubmit={handlePasswordReset} className="full-width rows">
                            <TextField
                                type="password"
                                placeholder="Password"
                                className="full-width"
                                name="password"
                                id="password"
                                status={error && "error"}
                                required
                            />
                            <TextField
                                type="password"
                                placeholder="Password (confirm)"
                                className="full-width"
                                name="password_confirm"
                                id="password_confirm"
                                status={error && "error"}
                                required
                            />
                            <div className="button-group form-action row">
                                <Button size="small" text="Reset Password" type="submit" className="light" />
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="footer dark full-width">
                    &copy; 2024, <a href="https://blindinsight.com">Blind Insight</a> | <a href="https://docs.blindinsight.io">View docs</a>
                </div>
            </div>
        </React.Fragment>
    )
}
