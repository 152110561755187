import React, { useState, useCallback } from "react"
import { useParams, useNavigate } from "react-router-dom"
import BIJSONEditor from "../components/JSONEditor"
import { Api } from "../lib/api"
import { Button } from "../components/Button"
import { Alert } from "@mui/material"
import TextField from "../components/TextField"
import { Datasets } from "../lib/store"

export default function schemaCreate() {
  const [error, setError] = React.useState(null)
  const [dataset, setDataset] = React.useState({})
  const [datasets,] = Datasets.useState()
  const { datasetId } = useParams()
  React.useEffect(() => {
    setDataset(datasets[datasetId])
  }, [datasets])

  const api = new Api()

  // Example schema
  const initialContent = {
    type: "object",
    properties: {
      name: {
        type: "string"
      },
      age: {
        type: "number",
        minimum: 1,
        maximum: 100
      }
    },
    required: ["name", "age"]
  }

  // Set the initial content to a pretty indented version that can be immediately submitted.
  const [jsonContent, setJsonContent] = useState({
    json: undefined,
    text: JSON.stringify(initialContent, null, 2)
  })

  const onChangeJSON = useCallback((updatedContent, previousContent, status) => {
    console.log('onChange', { updatedContent, previousContent, status })
    setJsonContent(updatedContent)
    console.log(">>> jsonContent", jsonContent)
  }, [jsonContent])

  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { name, description } = event.target.elements
    let schema

    // Invalid JSON will not parse.
    try {
      schema = JSON.parse(jsonContent.text)
    } catch (e) {
      setError("JSON schema is invalid. Please correct the errors before submitting.")
      return
    }

    try {
      await api.schemasCreate(dataset.url, name.value, description.value, schema)
      setError(null)
      await api.schemasList()
      navigate(`/datasets/${datasetId}`)
    } catch (e) {
      console.log("Failed to create schema", e)
      // TODO(jathan): For now display the raw JSON error. Sometimes it's "detail", others "schema".
      setError(JSON.stringify(await e.json()))
    }
  }

  return (
    <div className="view rows">
      <div className="section-header rows" id="create-schema-header">
        <h2>Add a schema to the {dataset.name} dataset</h2>

      </div>
      <div className="row full-width">
        <form onSubmit={handleSubmit} className="columns full-width">
          <div className="rows column top">
            <div>Dataset:  {dataset.name}</div>
            <TextField
              name="name"
              className="text-field small"
              required
              placeholder="Name"
              helperText="Human-readable name"
              id="fld-schema-name"
            />
            <TextField
              name="description"
              className="text-field small"
              placeholder="Description"
              helperText="Brief description"
              id="fld-schema-description"
            />
            <Button
            type="submit"
            text="Create schema"
            icon="add"
            id="btn-schema-create"
          />
          </div>
          <div className="column full-width">
            {error && <Alert severity="error">{error}</Alert>}
            <p>Use the sample schema below as a guide. Learn more in the <a href="https://docs.blindinsight.io/getting-started/what-is-blindinsight/#schemas" target="new">Schema documentation.</a></p>
            <BIJSONEditor
              content={jsonContent}
              onChange={onChangeJSON}
              readOnly={false}
              mode="text"
              mainMenuBar={false}
              id="fld-schema-content"
            />
            <p>Schemas must be in <a href="https://json-schema.org/understanding-json-schema/reference" target="new">JSON Schema</a> format and consist of fields with &quot;string&quot; or &quot;number&quot; types.</p>
            <p>Each number field must include values for it&apos;s &quot;minimum&quot; and &quot;maximum&quot; bounds.</p>
          </div>
        </form>
      </div>
    </div>)
}
