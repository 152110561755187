import React from "react"
import { useMemo } from "react"

import { Icon } from "./Icon"

import { useParams, Link } from "react-router-dom"


import logo from "../images/Logo.svg"

// TODO(shakefu): Don't use this component later
import ProfileMenu from "./ProfileMenu"

import "./Sidebar.css"

import {
    DatasetSchemas, Datasets, Schemas, PersistedNavigation
} from "../lib/store"

export default function SchemaSideBar() {
    const [persistedNavigation,] = PersistedNavigation.useState()
    const [datasetSchemas,] = DatasetSchemas.useState()
    const [datasets,] = Datasets.useState()
    const [schemas,] = Schemas.useState()
    const { datasetId, schemaId } = useParams()
    const schemaLink = useMemo(() => (
        persistedNavigation.schemas === undefined ? "" : (
            persistedNavigation.schemas ? `/${persistedNavigation.schemas}` : "")), [persistedNavigation])
    const selectedDatasetSchemas = useMemo(() => {
        // If we are viewing all datasets, don't render schemas
        if (!datasetId) return <></>
        // If we have no dataset selected, don't render schemas
        const selected = datasetSchemas[datasetId]
        if (!selected) return <></>
        // Loop over the selected dataset:schemas mapping creating our entries
        return selected.map((id) => {
            let schema = schemas[id]
            return (
                <Link
                        key={id}
                        className="menu-item child"
                        to={`/datasets/${datasetId}/schemas/${id}${schemaLink}`}
                    >
                        {schema.name}
                        </Link>

            )
        })
    }, [schemas, datasetId, schemaId, datasetSchemas, schemaLink])

    return (
        <>
        <div className="sidebar">
        <Link to="/"><img src={logo} alt="Blind Insight Logo"/></Link>
        <div className="navigation full-width">
            <Link to="/datasets" className="menu-item menu-section-header">
                <Icon type="dataset" />
                Datasets</Link>
                    {Object.values(datasets).map((dataset) => (
                        <React.Fragment key={dataset.id}>
                            <Link to={`/datasets/${dataset.id}`} className={`menu-item`}>
                                    {dataset.name}
                            </Link>
                            {dataset.id === datasetId ? selectedDatasetSchemas : null}
                        </React.Fragment>
                    ))}
                    </div>


            <ProfileMenu />
        </div>
        </>
    )
}
