import React, {useState} from "react"
import { useNavigate, useLocation, Form, Link } from "react-router-dom"
import Box from "@mui/material/Box"
import Checkbox from "./CheckBox"
import { Button } from "./Button"
import TextField from "./TextField"
import { Alert } from '@mui/material'
import { UserLogin, UserRegister } from "../lib/user"
import { onChange } from "../lib/util"
import TOU from "../views/TOU"
import Modal from "./Modal"

// Styles
import "./Login.css"


export default function Login() {
    let [authMode, setAuthMode] = useState("signin")
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [error, setError] = React.useState(null)
    const [success, setSuccess] = React.useState(null)
    const navigate = useNavigate()
    const location = useLocation()

    const login = async (event) => {
        event.preventDefault()
        console.log("Logging user in...")
        let success
        try {
            success = await UserLogin(username, password)
        } catch (e) {
            success = false
        }

        if (!success) {
            setPassword("")
            setSuccess(null)
            setError("Login failed")
        } else {
            setError(null)
            setUsername("")
            setPassword("")
            console.log("Login success")
            navigate(location)
        }
    }

    const handleRegister = async (event) => {
        event.preventDefault()

        const {email, password, fullName, organizationName} = event.target.elements
        let success
        try {
            success = await UserRegister(email.value, password.value, fullName.value, organizationName.value)
        } catch (e) {
            success = false
        }

        if (!success) {
            setError("Registration failed")
            setSuccess(null)
        }
        else {
            console.log("Registration success")
            setError(null)
            setSuccess("Registration successful. Please check your email for an activation link.")
            navigate("/accounts/register-confirm")
        }
    }
    const [open, setOpen] = React.useState(false)
    const handleClose = () => {
        setOpen(false)
      }

      const handleOpen = () => {
        setOpen(true)
      }

    const changeAuthMode = () => {
        setAuthMode(authMode === "signin" ? "signup" : "signin")
      }

    //Will need to be updated once we have a full release product
    const environment = () => {
        const fullUrl = window.location.href
        if (fullUrl.includes("demo")) {
            return "Demo"
        }
        else if (fullUrl.includes("beta") || fullUrl.includes("local"))
        {
            return "Beta"
        }
        else{
            return ""
        }
    }



    if (authMode === "signin") {
    return (
        <>
            <div className="container dark">
                <div className="section">
                    <div className="message">
                        <h1>Welcome to the Blind Insight {environment()}</h1>
                        <p>Sign in to the Blind Insight {environment()}.
                        Accounts are free for 30 days - no credit card required.<br /><br />
                        Free accounts are in a shared environment. Paid accounts receive their own private environment along with white-glove support.<br /><br />
                          If you have any questions you can always reach out through the Intercom messenger at the bottom right of
                          your screen, or see our <a href="https://docs.blindinsight.io/getting-started/" rel="noopener noreferrer" target="_blank"> Getting Started Guide.</a>
                        </p>
                    </div>
                    <div className="form-container">
                        <div className="logo-vertical" />
                        <div className="toggle-heading">
                            <h4>Sign In | </h4> <h4>&nbsp;<a href="#" onClick={changeAuthMode}>Sign Up</a></h4>
                        </div>

                        {error && <Alert severity="error">{error}</Alert>}

                        <Form onSubmit={login} className="full-width rows">
                            <TextField
                                autoComplete="username"
                                label="Email address"
                                value={username}
                                onChange={onChange(setUsername)}
                                placeholder="Email Address"
                                helperText={error || "Your email address is your username"}
                                className="full-width"
                                type="email"
                                name="email"
                                id="email"
                                required
                                validate
                            />
                            <TextField
                                type="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={onChange(setPassword)}
                                placeholder="Password"
                                className="full-width"
                                name="password"
                                id="password"
                                status={error && "error"}
                                helperText={error || <> <Link to="/accounts/forgot-password">Forgot password.</Link> Need to <a href="#" onClick={changeAuthMode}>Register?</a> </>}
                                required
                            />
                            <div className="button-group form-action row">
                                <Button size="small" text="Sign In" type="submit" className="light" />
                            </div>
                        </Form>
                    </div>
                </div>
                <div className="footer dark full-width">
                    &copy; 2024, <a href="https://blindinsight.com">Blind Insight</a> | <a href="https://docs.blindinsight.io">View docs</a>
                </div>
            </div>
        </>
    )}
    return (

        <>
             <div className="container dark">
                <div className="section">
                <div className="message">
            <h1>Welcome to the Blind Insight {environment()}</h1>
            <p>Sign up to access the Blind Insight {environment()} environment.<br /><br />
            Enter your organization and personal info to complete sign-up and access your account.<br /><br />
            Accounts are free for 30 days - no credit card required.<br /><br />
            Free accounts are in a shared environment. Paid accounts receive their own private environment along with white-glove support.<br /><br />
              If you have any questions you can always reach out through the Intercom messenger at the bottom right of
              your screen, or see our <a href="https://docs.blindinsight.io/getting-started/" rel="noopener noreferrer" target="_blank"> Getting Started Guide.</a>
            </p>  </div>
          <div className="form-container">
              <div className="logo-vertical" />
              <div className="toggle-heading"><h4>Sign Up | </h4> <h4>&nbsp;<a href="#" onClick={changeAuthMode}>Sign In</a></h4></div>

                        {error && <Alert severity="error">{error}</Alert>}
                        {success && <Alert severity="success">{success}</Alert>}

                        <Form onSubmit={handleRegister} className="full-width rows">
                        <TextField
                            autoComplete="username"
                            className="full-width"
                            name="email"
                            placeholder="Email Address"
                            type="email"
                            onChange={onChange(setUsername)}
                            value={username}
                            status={error && "error"}
                            helperText={error || "Your email address is your username"}
                            required
                        />
                        <TextField
                            autoComplete="password"
                            className="full-width"
                            name="password"
                            placeholder="Password"
                            type="password"
                            onChange={onChange(setPassword)}
                            status={error && "error"}
                            helperText={error || "Enter a password"}
                            value={password}
                            required
                        />
                        <TextField
                            className="full-width"
                            name='organizationName'
                            placeholder="Organization Name"
                            size="large"
                            type="text"
                            status={error && "error"}
                            helperText={error || " "}
                            required
                        />
                        <TextField
                            className="full-width"
                            name="fullName"
                            placeholder="Your Full Name"
                            size="large"
                            type="text"
                            status={error && "error"}
                            helperText={error || " "}
                            required
                        />

                        <div><Checkbox name="tos" required /> I agree to the <Link onClick={handleOpen}>Terms of Use.</Link></div>

                        <div><Checkbox name="robot" required /> I am not a robot.</div>

                        <Box className="form-action full-width">
                            <Button
                                size="large"
                                text="Sign Up"
                                className="light"
                                type="submit"
                            >
                            </Button>
                        </Box>

                        </Form>
<Modal isOpen={open} onClose={handleClose} heading="Terms of use"><TOU /></Modal>


            </div>
          </div>
        </div>
    </>
    )
}
