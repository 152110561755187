import React from "react"
import { Outlet, useLoaderData, useRouteError, json, isRouteErrorResponse } from "react-router-dom"
import { Box } from "@mui/material"

import { CurrentUser } from "../lib/store"
import { UserIsLoggedIn, UserRetrieve } from '../lib/user'
import SchemaSideBar from "./Sidebar"

import LoginPage from "./Login"

function AuthRequired() {
    const auth = useLoaderData()
    return <Outlet value={auth} />
}

AuthRequired.loader = async () => {
    const [loggedIn, hasAuth] = await UserIsLoggedIn()
    if (!loggedIn && !hasAuth) {
        console.log("No auth")
        throw json({ error: "No auth" }, { status: 403 })
    }

    if (loggedIn && hasAuth) {
        console.log("Is logged in")
        return CurrentUser.getValue()
    }

    if (!loggedIn && hasAuth) {
        console.log("Has auth")
        const ok = await UserRetrieve()
        if (!ok) {
            console.log("No user found")
            throw json({ error: "No user found" }, { status: 403 })
        }
        console.log("Logged user in")
        return CurrentUser.getValue()
    }
    console.log("Bad auth state")
    throw json({ error: "Bad auth state" }, { status: 403 })
}

export function AuthRequiredError() {
    const error = useRouteError()
    // console.debug(error)
    if (!isRouteErrorResponse(error)) {
        // const strErr = JSON.stringify(error, null, 2)
        return (
        <Box display="flex" height="100vh">
            <SchemaSideBar />
            <div className="main">
                <Box>
                    <Box>
                        <h1>Something is horribly wrong!</h1>
                        <pre>{error.stack}</pre>
                    </Box>
                </Box>
            </div>
        </Box>
        )
    }

    switch (error.status) {
        case 400:
            // No password given
            return <LoginPage />
        case 401:
            // Bad credentials
            return <LoginPage />
        case 403:
            return <LoginPage />
        case 422:
            // TODO(jathan): Display an actual proxy error screen.
            // Proxy error. Most likely failed to pass through auth.
            return <LoginPage />
        default:
            return ErrorPage(error)
    }
}

function ErrorPage(error){
    return <Box display="flex" height="100vh">
    <SchemaSideBar />
        <div className="main">
            <Box>
                <Box>
                    <h1>Something is horribly wrong!</h1>
                    <pre>{error.stack}</pre>
                </Box>
            </Box>
        </div>
    </Box>
}


export default AuthRequired
