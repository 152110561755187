import React from "react"
import { Outlet } from "react-router-dom"

import { CurrentUser, CurrentOrg, CurrentOrgId } from "../lib/store"
import { AUTH_ORGANIZATION_ID } from "../lib/api"
// import { isEmpty } from "../lib/util"

function CurrentOrganization() {
    const [currentUser, ] = CurrentUser.useState()
    const [org, setOrg] = CurrentOrg.useState()
    const [orgId, setOrgId] = CurrentOrgId.useState()
    console.log("Render CurrentOrganization")
    console.log("CurrentOrganization", {currentUser, org, orgId})

    React.useEffect(() => {
        // Can't do anything if we don't have a current User
        if (!currentUser) return

        if (!currentUser.organizations?.length) {
            console.log("No organizations")
            return
        }

        if (!orgId){
            // Retrieve it from the session storage
            let sessionOrgId = sessionStorage.getItem(AUTH_ORGANIZATION_ID)
            if (sessionOrgId) {
                setOrgId(sessionOrgId)
                return
            }

            // Default to using the first one found
            setOrgId(currentUser.organizations[0].id)
            return
        }

        const matching = currentUser.organizations.find(org => org.id === orgId)
        console.log("Matching org", matching)
        if (!matching) {
            console.log("No matching org found")
            return
        }

        setOrg(matching)
    }, [currentUser, org, setOrg, orgId, setOrgId])

    if (!currentUser || !org) return <></>
    return <Outlet />
}

export default CurrentOrganization
