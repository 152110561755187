import React from "react"
import "./CheckBox.css"

const Checkbox = (props) => {
  return (
    <input type="checkbox" className="inputs-checkbox" {...props} />
  )
}

export default Checkbox
